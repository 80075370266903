<template>
  <v-row no-gutters class="fill-height-lg mb-3 ceocornerdiv">
    <v-col cols="12">
      <v-card class="fill-height">
        <v-card-title
          @click.stop="dialog = true"
          class="borderBottom-1 border-accentxlight"
          >CEO's Corner</v-card-title
        >
        <v-card-text @click="showDetailDialog = true" class="cursor-pointer">
          <v-progress-circular
            v-if="ShowCEOPostLoading"
            value="true"
            indeterminate
            size="24"
            style="width: 100%"
          ></v-progress-circular>
          <v-row v-if="!ShowCEOPostLoading" no-gutters class="align-center">
            <v-col cols="12" md="auto">
              <img src="../../assets/ceo-corner.jpg" class="ceocornerimg" />
            </v-col>
            <v-col cols="12" class="pl-md-3 col-md">
              <div
                v-if="ceoCornerPost.id > 0"
                class="accentlight--text xsFont mb-1"
              >
                Date: {{ ceoCornerPost.createDateString }}
              </div>
              <div class="font-italic lgFont fw-500">
                {{ ceoCornerPost.title }}
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="my-2">
            <v-col cols="12" class="fw-400 xsFont p-relative">
              <div
                @click="showDetailDialog = true"
                class="ceomessage seemorediv ceopost cursor-pointer"
                :class="{
                  noimages:
                    ceoCornerPost.photosLink == undefined ||
                    ceoCornerPost.photosLink.length == 0,
                }"
              >
                <div v-html="ceoCornerPost.description"></div>
              </div>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            v-if="
              ceoCornerPost.photosLink != undefined &&
              ceoCornerPost.photosLink.length > 0
            "
          >
            <v-col cols="12" class="ceocarousel">
              <v-carousel hide-delimiters>
                <v-carousel-item
                  v-for="(item, i) in ceoCornerPost.photosLink"
                  :key="i"
                >
                  <iframe
                    v-if="item.type == 'drive'"
                    :src="item.imageLink"
                    width="340px"
                    height="280px"
                  ></iframe>
                  <iframe
                    v-if="item.type == 'youtube'"
                    width="340px"
                    height="280px"
                    :src="item.imageLink"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <video-player
                    v-if="item.type == 'video'"
                    style="height: 200px !important; width: 100% !important"
                    class="video-player-box"
                    ref="videoPlayer"
                    :options="item.playerOptions"
                    :playsinline="true"
                  />
                  <img v-if="item.type == 'image'" :src="item.imageLink" />
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
          <reaction-component
            v-if="ceoCornerPost.id > 0"
            :postId="ceoCornerPost.id"
            :selectedReaction="this.selectedReaction"
            :listofLikedReactions="this.listofLikedReactions"
            :listofLovedReactions="this.listofLovedReactions"
            :listofApplauseReactions="this.listofApplauseReactions"
            :listofAllReactions="this.listofAllReactions"
            @reaction-selected="handleReaction"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="showDetailDialog" max-width="700" scrollable>
      <v-card class="mb-0">
        <v-card-title class="pa-3">
          <v-row
            class="align-center borderBottom-1 border-accentxlight mx-n5 px-2"
          >
            <v-col class="pb-2">CEO's Corner</v-col>
          </v-row>
        </v-card-title>
        <vue-scroll>
          <v-card-text class="pa-0 accentdark--text">
            <v-row class="mx-0">
              <v-col class="px-0"> </v-col>
            </v-row>

            <v-row class="ma-0 pa-3">
              <v-col cols="12" class="pt-0">
                <v-row no-gutters class="align-center mb-3">
                  <v-col cols="12" md="auto" class="line-height-0">
                    <img
                      src="../../assets/ceo-corner.png"
                      class="ceocornerimg"
                    />
                  </v-col>
                  <v-col cols="12" md="auto" class="pl-3 pr-8">
                    <div class="fw-700">Masood Jaffery</div>
                    <div class="accentlight--text xxsFont mb-1">
                      Date: {{ ceoCornerPost.createDateString }}
                    </div>
                  </v-col>
                  <v-col class="lgFont fw-500">
                    {{ ceoCornerPost.title }}
                  </v-col>
                </v-row>

                <v-row no-gutters>
                  <v-col cols="12" class="xsFont">
                    <p v-html="ceoCornerPost.description"></p>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="mx-auto"
                    v-if="
                      ceoCornerPost.photosLink != undefined &&
                      ceoCornerPost.photosLink.length > 0
                    "
                  >
                    <v-slide-group
                      v-model="model"
                      class="px-0 py-1 newsslider ceoslider"
                      show-arrows
                    >
                      <v-slide-item
                        class="px-2"
                        v-for="(item, i) in ceoCornerPost.photosLink"
                        :key="i"
                      >
                        <div class="ceomodalitems">
                          <iframe
                            v-if="item.type == 'drive'"
                            :src="item.imageLink"
                            width="200px"
                            height="180px"
                          ></iframe>
                          <iframe
                            v-if="item.type == 'youtube'"
                            width="200px"
                            height="180px"
                            :src="item.imageLink"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <video-player
                            v-if="item.type == 'video'"
                            class="video-player-box"
                            ref="videoPlayerPopup"
                            :options="item.playerPopupOptions"
                          />
                          <img
                            v-if="item.type == 'image'"
                            :src="item.imageLink"
                          />
                        </div>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
              </v-col>
              <reaction-component
                :postId="ceoCornerPost.id"
                :selectedReaction="this.selectedReaction"
                :listofLikedReactions="this.listofLikedReactions"
                :listofLovedReactions="this.listofLovedReactions"
                :listofApplauseReactions="this.listofApplauseReactions"
                :listofAllReactions="this.listofAllReactions"
                @reaction-selected="handleReaction"
              />
            </v-row>
          </v-card-text>
        </vue-scroll>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-model="showDetailDialog" max-width="600px" v-if="ceoCornerPost">
      <v-card class="mb-0">
        <v-card-title>
          <v-row>
            <v-col class="fw-500 accentdark--text">
              {{ ceoCornerPost.title }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="fw-500 accentdark--text" v-html="ceoCornerPost.description">
            </v-col>
          </v-row>
          <v-row class="justify-end mt-1">
            <v-col cols="12" class="text-right">
              <v-btn
                outlined

                class="accentlight--text mr-2"
                small
                @click="showDetailDialog = false"
                >Ok</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </v-row>
</template>

<script>
import apimethods from "../../mixins/apimethods";
import helpermethods from "../../mixins/helpermethods";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import ReactionComponent from "./Reaction.vue";

export default {
  name: "CEOCorner",
  components: {
    videoPlayer,
    ReactionComponent,
  },
  mixins: [apimethods, helpermethods],
  data() {
    return {
      ceoCornerPost: {},
      listofActiveMagazine: [],
      HideCEOPost: false,
      ShowCEOPostLoading: false,
      showDetailDialog: false,
      dialog: false,
      listofAllReactions: [],
      listofLikedReactions: [],
      listofLovedReactions: [],
      listofApplauseReactions: [],
      isReactionSelected: false,
      selectedReaction: 0,
    };
  },
  created() {
    this.GetActiveCEOPost();
  },
  methods: {
    GetActiveCEOPost() {
      try {
        this.ShowCEOPostLoading = true;
        var self = this;
        var request = this.GetRequestWithAuthorization(
          "api/CEOCorner/GetActivePost",
          undefined
        );
        if (request != undefined) {
          request.then((res) => {
            self.ShowCEOPostLoading = false;
            if (
              res != undefined &&
              res.data != undefined &&
              res.data.data != undefined
            ) {
              self.ceoCornerPost = res.data.data;
              self.listofAllReactions = self.ceoCornerPost.reactions;
              self.listofLikedReactions = self.listofAllReactions.filter(
                (x) => x.reactionId == 1
              );
              self.listofLovedReactions = self.listofAllReactions.filter(
                (x) => x.reactionId == 2
              );
              self.listofApplauseReactions = self.listofAllReactions.filter(
                (x) => x.reactionId == 3
              );

              self.listofAllReactions.forEach((reaction) => {
                if (reaction.isReactionSelected) {
                  this.isReactionSelected = true;
                  this.selectedReaction = reaction.selectedReaction;
                }
              });

              if (this.ceoCornerPost.photosLink) {
                this.ceoCornerPost.photosLink.forEach((element) => {
                  if (element.type == "video") {
                    element.playerOptions = {
                      // videojs options
                      muted: false,
                      language: "en",
                      width: "335px",
                      height: "200px",
                      sources: [
                        {
                          type: "video/mp4",
                          src: element.imageLink,
                        },
                      ],
                    };
                    element.playerPopupOptions = {
                      // videojs options
                      muted: false,
                      language: "en",
                      width: "250px",
                      height: "180px",
                      sources: [
                        {
                          type: "video/mp4",
                          src: element.imageLink,
                        },
                      ],
                    };
                  }
                });
              }

              if (self.ceoCornerPost == undefined) {
                self.ceoCornerPost = true;
              }
              setTimeout(() => {
                self.allocateseemorediv(null);
              }, 10);
            } else {
              self.HideCEOPost = true;
              self.ShowCEOPostLoading = false;
            }
          });
        }

        request.catch((ex) => {
          this.ShowNewspaperLoading = false;
        });
      } catch (exa) {
        this.ShowNewspaperLoading = false;
        console.log(exa);
      }
    },
    handleReaction(reactionData) {
      const { postId, selectedReaction } = reactionData;
      if (
        this.isReactionSelected &&
        selectedReaction == this.selectedReaction
      ) {
        this.removeReaction(postId);
      } else if (this.isReactionSelected) {
        this.editReaction(postId, selectedReaction);
      } else {
        this.addReaction(postId, selectedReaction);
      }
    },
    addReaction(postId, reactionId) {
      this.$store.commit("showLoadingModal");
      this.isSavingReaction = true;

      var bodyFormData = new FormData();
      bodyFormData.append("postId", postId);
      bodyFormData.append("reactionId", reactionId);
      var url = "api/Reaction/AddCeoCornerPostReaction";
      this.PostFormRequestWithAuthorization(url, undefined, bodyFormData)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            this.$notify({
              group: "Reaction",
              title: "Message",
              text: "Reaction Added Successfully",
              type: "Success",
            });
          } else {
            this.$notify({
              group: "notification",
              title: "Error",
              text: "Error Adding Reaction",
              type: "error",
            });
          }
          this.GetActiveCEOPost();
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          console.log(ex);
        });
    },
    editReaction(postId, reactionId) {
      this.$store.commit("showLoadingModal");
      this.isSavingReaction = true;

      var bodyFormData = new FormData();
      bodyFormData.append("postId", postId);
      bodyFormData.append("reactionId", reactionId);
      var url = "api/Reaction/EditCeoCornerPostReaction";
      this.PostFormRequestWithAuthorization(url, undefined, bodyFormData)
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            this.$notify({
              group: "notification",
              title: "Message",
              text: "Reaction Update Successfully",
              type: "Success",
            });
          } else {
            this.$notify({
              group: "notification",
              title: "Error",
              text: "Error Updating Reaction",
              type: "error",
            });
          }
          this.GetActiveCEOPost();
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          console.log(ex);
        });
    },
    removeReaction(postId) {
      this.$store.commit("showLoadingModal");
      this.isSavingReaction = true;

      var url = "api/Reaction/RemoveCeoCornerPostReaction";
      this.GetRequestWithAuthorization(url, [
        {
          Key: "postId",
          Value: postId,
        },
      ])
        .then((res) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          if (
            res != undefined &&
            res.data != undefined &&
            res.data.status == true
          ) {
            this.$notify({
              group: "notification",
              title: "Message",
              text: "Reaction Deleted Successfully",
              type: "Success",
            });
          } else {
            this.$notify({
              group: "notification",
              title: "Error",
              text: "Error Removing Reaction",
              type: "error",
            });
          }
          this.isReactionSelected = false;
          this.selectedReaction = 0;
          this.GetActiveCEOPost();
        })
        .catch((ex) => {
          this.$store.commit("hideLoadingModal");
          this.isSavingReaction = false;
          console.log(ex);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
</style>
